import "./credentials.input.css";
import React, { useState } from 'react';
const CredentialsInput = ({ form_type, form_placeholder, error, onChange }) => {
  const handleChange = (value) => { 
    onChange(value);
  };

  return (
    <div className="credentials_input_container">
      <input
        type={form_type}
        className={`credentials_input ${error ? 'error' : ''}`} 
        placeholder={form_placeholder}
        onChange={(e) => handleChange(e.target.value)}
        required
      />
      {error && <div className="input_error_message">{error}</div>}
    </div>
  );
};

export default CredentialsInput;
