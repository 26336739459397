import axios, { Axios } from "axios";

class TokensService {
    constructor(tokensApi, tokensProvider, axios: Axios) {
        this.tokensApi = tokensApi;
        this.tokensProvider = tokensProvider;
        this.axios = axios
    }

    getTokens() {
        var token = this.tokensProvider.get()

        // if(token == undefined){
        //     token = await this.tokensApi.authorize('0dd130b7-05a8-4911-a554-8c36a929152e') // пока что рандомный гуид, как будет авторизация, будем логиниться
        //     this.tokensProvider.set(token)
        // }

        return token;
    }

    async getUserInfo() {
        const response = await this.axios.get('identity/api/identity')

        return response.data;
    }

    setToken(token) {
        this.tokensProvider.set(token)
    }

    remove() {
        this.tokensProvider.remove()
    }
}

export default TokensService;