import NewWord from "../newWords/new.word";
import ExerciseWordButton from "../ExerciseWordButton/exercise.word.button";
import Loader from "../loader/loader.component";
import React, { useEffect, useState, forwardRef, useImperativeHandle, useLayoutEffect } from "react";
import factorySingleton from "../../services/factory";

const ChooseWordExercise = forwardRef(({ topicId, onSuccess, onFail }) => {
    const exercisesApi = factorySingleton.exercisesApi;

    const [currentExerciseId, setCurrentExerciseId] = useState(null);
    const [exerciseWords, setExerciseWords] = useState([]);
    const [targetWord, setTargetWord] = useState(null);
    const [correctWord, setCorrectWord] = useState(null);
    const [loading, setLoading] = useState(false);

    useLayoutEffect(() => {
        const fetchData = async () => {
            await setup();
        };

        fetchData();
    }, []);

    const setup = async () => {
        setLoading(true);
        try {
            const exercises = await exercisesApi.getChooseWordExercise(topicId);
            setCurrentExerciseId(exercises.id);
            setTargetWord(exercises.target.name);

            setExerciseWords(exercises.options.map(x => ({
                id: x.id,
                word: x.name,
                state: 'Common'
            })));
        } finally {
            setLoading(false);
        }
    };

    const handleWordClick = async (e, index) => {
        setLoading(true);
        try {
            const correctResponse = await exercisesApi.getCorrect(topicId, currentExerciseId);
            const correctWord = exerciseWords.find(x => x.id === correctResponse.wordId);
            const correct = correctWord.id === exerciseWords[index].id;

            if (correct) {
                onSuccess();
            } else {
                onFail(correctWord.word);
            }

            const state = correct ? "Success" : "Fail";

            const exercises = exerciseWords.map((x, i) => ({
                id: x.id,
                word: x.word,
                state: index === i ? state : "Common"
            }));

            setExerciseWords(exercises);
            setCorrectWord(correctWord.word);
        } finally {
            setLoading(false);
        }
    };

    // useImperativeHandle(ref, () => ({
    //     setup: setup
    // }));

    return (
        <div>
            {loading && <Loader />}
            <NewWord text={targetWord} />
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "row",
                }}
            >
                {exerciseWords.length > 0 && exerciseWords.map((wordObj, index) => (
                    <ExerciseWordButton
                        key={wordObj?.id}
                        id={index}
                        word={wordObj?.word}
                        state={wordObj?.state}
                        onClick={handleWordClick}
                    />
                ))}
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "12%",
                }}
            >
            </div>
        </div>
    );
});

export default ChooseWordExercise;
