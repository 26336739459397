import "./Search.css";
import React, { useState, useEffect } from 'react';

function Search({ onSearch, value, onChange }) {
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (onSearch) {
      const delayDebounceFn = setTimeout(() => {
        onSearch(searchTerm);
      }, 300);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [searchTerm, onSearch]);

  const handleInputChange = (event) => {
    if (onChange) {
      onChange(event.target.value)
    }
    setSearchTerm(event.target.value);
  };

  return (
    <div className="search_block">
      <input
        className="search_bar"
        type="search"
        value={value ? value : searchTerm}
        onChange={handleInputChange}
      />
    </div>
  );
}

export default Search;
