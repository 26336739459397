import "./progress.bar.css";
import CloseButton from "../../components/closeButton/close.button";
const ProgressBar = ({ variable }) => {
  const progress = variable;

  return (
    <div className="progress_bar_container">
      <CloseButton />

      <div className="progress_wrapper">
        <div className="progress">
          <div className="progress_bar" style={{ width: `${progress}%` }}></div>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
