import React, { useEffect, useState, useRef } from 'react';
import './topic.menu.css';
import { useHistory, useNavigate } from 'react-router-dom';

function TopicMenu({ position, topicId }) {
    console.log('topicId ', topicId)
    const [visible, setVisible] = useState(false);
    const navigator = useNavigate()

    const ref = useRef(null);

    const menuStyle = {
        position: 'absolute',
        top: `${position.top}px`,
        left: `${position.left}px`,
        right: `${position.right}px`,
        bottom: `${position.bottom}`
    };

    useEffect(() => {
        console.log(position)
        // Trigger the animation by setting visible to true
        setVisible(position != null);
    }, [position]);

    useEffect(() => {
        // Функция обработчика клика
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                console.log('Клик вне компонента');
                setVisible(false)
            }
        };

        // Добавляем обработчик события клика
        document.addEventListener('mousedown', handleClickOutside);

        // Удаляем обработчик события при размонтировании компонента
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);

    return (
        <div className={`container ${visible ? 'show' : ''}`} style={menuStyle} ref={ref}>
            <div className="top-rounded">
                <div className="button" onClick={() => {
                    navigator('/exercises', { state: { topicId: topicId } })
                }}>
                    <span>Exercises</span>
                    <span className="arrow">→</span>
                </div>
                {/* <div className="button" onClick={() => {
                    console.log('click')
                    navigator('/dictionary', { state: { topicId: topicId } })
                }}>
                    <span>Dictionary</span>
                    <span className="arrow">→</span>
                </div> */}
            </div>
        </div>
    );
}

export default TopicMenu;