import "./loading.payment.popup.css"
const LoadingPaymentPopup = () => {
  return (
    <div className="PaymentPagePopUp_wrapper">
      <div className="PaymentPagePopUp_container loading_popup_container">
        
        <div className="subscribe_popUp_title loading_popup_title">Checking the payment</div>
        <div className="loader"></div>
      </div>
    </div>
  );
};

export default LoadingPaymentPopup;
