import { useEffect, useRef, useState } from "react";
import factorySingleton from "../../services/factory";
import { useNavigate } from "react-router-dom";
import PaymentPagePopUp from "../payment-page-popUp/payment.page.popUp";
import { useTranslation } from 'react-i18next';

const ResponseInterceptor = () => {
    const { t, i18n } = useTranslation();
    const [offerSubscribe, setOfferSubscribe] = useState(false)

    const navigate = useNavigate();

    const interceptorId = useRef(null);
    const interceptorId2 = useRef(null);
    const tokensService = factorySingleton.tokensService;

    useEffect(() => {
        interceptorId.current = factorySingleton.axiosInstance.interceptors.response.use(
            (response) => response,
            (error) => {
                switch (error.response.status) {
                    case 401:
                        tokensService.remove();
                        navigate("/sign-in");
                        break
                    case 403:
                        setOfferSubscribe(true)
                    default:
                        return Promise.reject(error);
                }
            }
        );

        interceptorId2.current = factorySingleton.axiosGatewayInstance.interceptors.response.use(
            (response) => response,
            (error) => {
                switch (error.response.status) {
                    case 401:
                        tokensService.remove();
                        navigate("/sign-in");
                        break
                    case 403:
                        setOfferSubscribe(true)
                    default:
                        return Promise.reject(error);
                }
            }
        );
    }, []);

    function onSubscriptionClick() {
        navigate('/subscriptions')
        setOfferSubscribe(false)
    }

    function goToRoot(){
        console.log('root')
        navigate('/')
        setOfferSubscribe(false)
    }

    return (<>
        {offerSubscribe
            && <PaymentPagePopUp
                title={t('popUp:testPeriodEnded')}
                icon={'./images/subscription.popup.svg'}
                buttonText={'Subscribe'}
                onCrossClick={goToRoot}
                onClick={onSubscriptionClick}
            />}
    </>)
}

export default ResponseInterceptor