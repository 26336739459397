import { Axios } from "axios";

class WordsApi {
    constructor(axios, languageProvider) {
        this.axios = axios;
        this.languageProvider = languageProvider;
    }

    async exists(topicId) {
        const params = {
            languageId: await this.languageProvider.getTargetId()
        }

        try {
            const response = await this.axios.head(`topics/${topicId}/words/views`, { params })
            if (response.status == 204)
                return true;
        } catch (error) {
            if (error.response && error.response.status === 404) {
                return false;
            }

            throw error;
        }
    }

    async getViewed(topicId, languageId, search, skip, limit) {
        const params = {
            search,
            languageId
        }

        const headers = {
            skip,
            limit
        }

        try {

            const response = await this.axios.get(`topics/${topicId}/words/views`, { params, headers });
            return response.data;
        }
        catch (error) {
            throw error;
        }
    }

    async getTranslates(topicId, wordIds) {
        const params = { wordIds }

        const response = await this.axios.get(`topics/${topicId}/words/translate`, { params })

        return response.data;
    }
}

export default WordsApi; 