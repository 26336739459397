class ExplanationsApi{
    constructor(axios) {
        this.axios = axios;
    }

    async get(topicId, wordIds){
        const params = { wordIds }

        const response = await this.axios.get(`topics/${topicId}/words/explanations`, { params })

        return response.data;
    }
}

export default ExplanationsApi;