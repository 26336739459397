import logo from "./logo.svg";
import "./App.css";
import factorySingleton from "./services/factory";
import Header from "./components/header/Header";
import SearchScreen from "./screens/home/home";
import TopicOwerviewScreen from "./screens/TopicOwerviewScreen/TopicOwerviewScreen";
import DataView, { useState } from "react";
import { BrowserRouter as Router, Route, Routes, Link, useLocation, BrowserRouter } from "react-router-dom";
import Dictionary from './screens/dictionaryScreen/dictionary'
import Topics from "./screens/topics/topics.screen";
import TopicMenu from "./components/topic-menu/topic.menu";
import Exercise from "./screens/exerciseScreen/exercise"
import GenerateTopicScreen from "./screens/generateTopicScreen/generate.topic"
import LoginScreen from "./screens/loginScreen/login"
import RegistrationScreen from "./screens/registrationScreen/registration"
import ResponseInterceptor from "./components/common/response.interceptor";
import SubscriptionScreen from "./screens/subscription/subscription"
import SubscripedPopup from "./components/subscriptions/subscribed.popup";
import PaymentPagePopUp from './components/payment-page-popUp/payment.page.popUp'
import SubscriptionCheker from "./components/common/subscription.checker.job";
import Footer from "./components/footer/footer";
import { useLayoutEffect } from "react";
import { UmamiTracker } from './helpers/umamu';
import { useTranslation } from 'react-i18next';
import Loader from "./components/loader/loader.component";

function App() {
  const location = useLocation();
  factorySingleton.setup();
  const { t, ready } = useTranslation();

  if (!ready) {
    return (<Loader />)
  }

  return (
    <div className="app-container">
      <UmamiTracker />
      <ResponseInterceptor />
      {isShowHeader(location.pathname) && <Header />}
      <Routes>
        <Route path='' element={<Topics />} />
        <Route path="/exercises" element={<Exercise />} />
        <Route path="/generation" element={<GenerateTopicScreen />} />
        <Route path="/sign-in" element={<LoginScreen />} />
        <Route path="/sign-up" element={<RegistrationScreen />} />
        <Route path="/subscriptions" element={<SubscriptionScreen />} />
        <Route path="/subscriptions/payments/success/:sessionId" element={<SubscriptionScreen />} />
      </Routes>
      {isShowFooter(location.pathname) && <Footer />}
    </div>
  );
}

function isShowHeader(path) {
  if (path === "/") {
    return true;
  }

  if (path == "/generation") {
    return true
  }

  return false;
}

function isShowFooter(path) {
  // if (path === "/") {
  return true;
  // }



  // return false;
}

export default App;
