import "./set.language.drop.css"
import { useLocation } from 'react-router-dom';
const SetLanguageDrop=()=>{
    const location = useLocation();
    const showSetLanguageDrop = location.pathname === '/sign-in' || location.pathname === '/sign-up';
    return(
        <div>
           {showSetLanguageDrop&&<select className="language_list">
            <option >English</option>
            <option >Русский</option>
            <option >Українська</option>
            </select>}
        </div>
    )
}
export default SetLanguageDrop;