import React, { useEffect, useState, useImperativeHandle, forwardRef, useLayoutEffect } from "react";
import "./voice.exercise.css";
import ListenWordButton from "../listenWordButton/listen.word.button";
import TalkButton from "../talkButton/talk.button";
import factorySingleton from "../../services/factory";
import Loader from "../loader/loader.component";

const VoiceExercise = forwardRef(({ topicId, onResult }, ref) => {
  const [loading, setLoading] = useState(false);
  const [exercise, setExercise] = useState(null);
  const exercisesApi = factorySingleton.exercisesApi;

  useLayoutEffect(() => {
    const fetchData = async () => {
      await setup();
    };

    fetchData();
  }, []);

  const setup = async () => {
    setLoading(true);
    try {
      const exercise = await exercisesApi.getTalkExercise(topicId);
      setExercise(exercise);
    } finally {
      setLoading(false);
    }
  };

  const onRecorded = async (bu, blob) => {
    setLoading(true);
    try {
      const res = await exercisesApi.checkTalkExercise(topicId, exercise.exerciseId, blob);
      console.log(res);
      onResult(res, exercise?.text);
    } finally {
      setLoading(false);
    }
  };

  useImperativeHandle(ref, () => ({
    setup,
  }));

  return (
    <div className="voice_exercise_wrapper">
      {loading && <Loader />}
      <div className="voice_exercise_container">
        <p
          style={{
            fontFamily: "Montserrat",
            fontWeight: 600,
            fontSize: "40px",
            lineHeight: "56px",
            textAlign: "center",
          }}
        >
          Repeat this sentence out loud
        </p>
        <ListenWordButton text={exercise?.text} topicId={topicId} wordId={exercise?.wordId} />
        <TalkButton onRecorded={onRecorded} />
      </div>
    </div>
  );
});

export default VoiceExercise;
