import "./exercise.word.flexible.css";

const ExerciseWordFlexible = ({ word, onDragStart, onClick }) => {
  return (
    <div className="exercise_word_flexible_wrapper">
      <div className="drag_and_drop_exercise_word">
        <div
          style={{
            padding: "24px 32px 24px 32px",
          }}
          draggable
          onDragStart={onDragStart}
          onClick={onClick}>
          {word}
        </div>
      </div>
    </div>
  );
};

export default ExerciseWordFlexible;
