import "./payment.page.button.css";
import React, { useEffect, useState } from "react";
const PaymentPageButton = ({
  title,
  titleColor,
  buttonColor,
  hoverColor,
  onClick,
  showError,
  errorMessage,
  enabled,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const hoverColorViolet = "#896daa";
  const hoverColorWhite = "#F1E5FF";
  const selectedHoverColor =
    hoverColor === "hoverColorViolet" ? hoverColorViolet : hoverColorWhite;

  return (
    <div class="payment_page_button_wrapper">
      <button
        className="payment_page_button"
        style={{
          color: `${titleColor}`,
          backgroundColor: enabled
            ? isHovered
              ? selectedHoverColor
              : buttonColor
            : "#D2C2E6",
          transition: "0.3s",
        }}
        onClick={onClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        disabled={!enabled}
      >
        {title}
      </button>
      {showError && (
        <div className="paymentButton_error_message">{errorMessage}</div>
      )}
    </div>
  );
};

export default PaymentPageButton;
