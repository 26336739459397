import "./listen.word.button.css";
import factorySingleton from "../../services/factory";

const ListenWordButton = ({ text, topicId, wordId, exerciseId }) => {
  let audio = null;
  if (topicId != null && wordId != null) {
    audio = new Audio(
      `${factorySingleton.apiBaseUrl}/topics/${topicId}/words/${wordId}/audio`
    );
  }

  if (topicId != null && exerciseId != null) {
    audio = new Audio(
      `${factorySingleton.apiBaseUrl}/topics/${topicId}/compose/${exerciseId}/originals/audio`
    );
  }

  return (
    <div className="listen_container">
      <div className="voice_exercise_icon">
        <img src="./images/voice_exercise-icon.svg" alt="" />
      </div>

      <div className="listen_button" onClick={() => audio?.play()}>
        <img
          src="./images/sound-icon.svg"
          alt=""
          style={{
            width: "26.67px",
            height: "21.33px",
            paddingRight: "15px",
            marginLeft: "15px",
          }}
        />
        <span style={{ margin: "16.5px 12px 16.5px " }}>{text}</span>
      </div>
    </div>
  );
};
export default ListenWordButton;
