import "./new.word.css";

const NewWord = ({ text }) => {
  return (
    <div>
      <div className="new_word_wrapper">
        <div className="new_word-icon">
          <img src="/images/newWord.svg" alt="" />
        </div>
        <div className="new_word_logo">New Word</div>
      </div>
      <span className="new_word_translate">
        Which one of these is “{text}”?
      </span>
    </div>
  );
};
export default NewWord;
