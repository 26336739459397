class TokenProvider {
  static TOKEN_KEY = 'TOKEN';

  set(token) {
    window.localStorage.setItem(TokenProvider.TOKEN_KEY, token);
  }

  remove() {
    window.localStorage.removeItem(TokenProvider.TOKEN_KEY);
  }

  get() {
    return window.localStorage.getItem(TokenProvider.TOKEN_KEY);
  }
}

export default TokenProvider;