class TokensApi {
    constructor(axios) {
        this.axios = axios
    }

    async authorize(userId) {
        // const response = await this.axios.get(`authorization/${userId}`);
        // if (response.status != 200) {
        //     throw new Error()
        // }

        return '669d84a3970c5e223ce20bf1'//response.data.accessToken;
    }
}

export default TokensApi;