import { useEffect, useState } from "react";
import factorySingleton from "../../services/factory";
import PaymentPagePopUp from "../payment-page-popUp/payment.page.popUp";
function SubscriptionCheker() {
    const subscriptionsApi = factorySingleton.subscriptionsApi;

    const [previousSubscription, setPreviousSubscription] = useState(null);
    const [subscribed, setSubscribed] = useState(false);
    useEffect(() => {
        const interval = setInterval(async () => {
            if (factorySingleton.tokensService.getTokens()) {
                const userInfo = await factorySingleton.tokensService.getUserInfo();
                const sub = await subscriptionsApi.getSubscription(userInfo.id);

                if (sub.type === 'trial') {
                    setPreviousSubscription((prevSub) => {
                        return sub;
                    });
                } else {
                    setPreviousSubscription((prevSub) => {
                        if (sub.type === 'plus' && prevSub?.type === 'trial') {
                            setSubscribed(true);
                            console.log('subscribed');
                        }
                        return prevSub;
                    });
                    clearInterval(interval);
                    return;
                }
            }
        }, 3000);

        return () => clearInterval(interval);
    }, []);

    function onClick() {
        setSubscribed(false)
    }

    return (<>
        {subscribed &&
            <PaymentPagePopUp
                title={'Congratulations, you have subscribed'}
                icon={'./images/birthday_party_confetti.svg'}
                buttonText={'Continue'}
                onClick={onClick}
                onCrossClick={onClick} />}
    </>)
}

export default SubscriptionCheker;