import "./generate.topic.error.css";
import { useRef } from "react";
const GenerateTopicError = ({ topic_name }) => {
  const topicName = useRef(topic_name)
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <p
        style={{
          fontFamily: "Montserrat",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "22.4px",
        }}
      >
        There is no theme
        <span style={{ fontWeight: 500 }}>"{topicName.current}"</span>
      </p>
      <p
        style={{
          fontFamily: "Inter",
          fontWeight: 600,
          fontSize: "34px",
          lineHeight: "47.6px",
          color: "#474747",
        }}
      >
        Could not generate anything for your request
      </p>
    </div>
  );
};
export default GenerateTopicError;
