import "./not.active.subscription.css";
import PaymentPageButton from "../payment-page-button/payment.page.button";
import { useTranslation } from 'react-i18next';
const NotActiveSubscription = ({ status, price, buyClick }) => {
  const { t, i18n } = useTranslation();
  const examples = t('subscription:examples', { returnObjects: true });
  return (
    <div className="not_active_subscription_wrapper">
      <div className="not_active_subscription_container">
        <span className="not_active_subscription_plan">{status}</span>
        <span className="subscription_price">${price}{t('subscription:subscriptionPrice')}</span>
        <div className="subscriptions_payment_button">
          <PaymentPageButton
            title={t('subscription:base:trial:buySubscriptionButton')}
            buttonColor="#FFFFFF"
            titleColor="#A180C8"
            hoverColor="hoverColorWhite"
            enabled="disabled"
            onClick={() => buyClick()}
          />
        </div>
        <ul className="subscription_info">
        {examples.map((x, i) =>
            <li key={i}>{x}</li>
          )
          }
        </ul>
      </div>
    </div>
  );
};

export default NotActiveSubscription;
