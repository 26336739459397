import "./footer.css"
import SetLanguageDrop from "../set-language-drop/set.language.drop";
const Footer = () => {
    return (
        <footer className="support_footer">
            <p>support: support@polyglotio.com</p>
            <div style={{display:"flex",justifyContent:"center",gap:"10px"}}>
                <SetLanguageDrop/>
            <a
                    href="https://www.freeprivacypolicy.com/live/7664cb86-9c00-4a96-9e76-7d6425f1145f"
                    target="_blank"
                    className="text-blue-500 hover:underline"
                >
                    Privacy Policy
                </a>
            </div>

        </footer>
    );
};

export default Footer;