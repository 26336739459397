import "./close.button.css";
import { useNavigate } from 'react-router-dom';

const CloseButton = ({ customClose }) => {
  const navigate = useNavigate()

  const handleClose = () => {
    if (customClose) {
      customClose()
    }
    else {
      console.log('close')
      navigate(-1)
    }
  }

  return (
    <div onClick={handleClose}>
      <button className="close_button" onClick={handleClose}></button>
    </div>
  );
};
export default CloseButton;
