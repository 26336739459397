import Loader from "../loader/loader.component";
import "./generate.description.css"
import { useTranslation } from 'react-i18next';
const GenerateDescription = () => {
  const { t, ready } = useTranslation();

  const examples = t('generation:examples', { returnObjects: true });
  console.log('map: ' + examples)
  return (
    <div className="generate_description_wrapper">
      <div className="generate_description_container">
        <span class="generate_description_examples">{t('generation:examplesTitle')}</span>
        <ul className="generate_description_list" >
          {examples.map((x, i) =>
            <li key={i}>{x}</li>
          )
          }
        </ul>
      </div>
    </div>
  )
}
export default GenerateDescription;