// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import HttpApi from 'i18next-http-backend';

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: `${process.env.REACT_APP_ApiBaseUrl}/localization/{{lng}}`,
    },
    fallbackLng: 'en',
    debug: true,
    keySeparator: ':',
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

  export default i18n;

