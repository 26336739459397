import { Axios } from 'axios';

class ExercisesApi {
    constructor(axios) {
        this.axios = axios;
    }

    async getChooseWordExercise(topicId) {
        const response = await this.axios.get(`topics/${topicId}/exercises/chose-correct-word`);
        return response.data;
    }

    async getTalkExercise(topicId) {
        const response = await this.axios.get(`topics/${topicId}/exercises/talks/word`);

        return response.data;
    }

    async checkTalkExercise(topicId, exerciseId, blob) {
        const formData = new FormData();
        formData.append('', blob, 'recording.wav');

        try {
            const response = await this.axios.post(`topics/${topicId}/exercises/talks/word/${exerciseId}/check`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });

            return response.status == 202;
        }
        catch (error) {
            if (error.response.status === 409) {
                return false
            }

            throw error;
        }
    }

    async getComposeExercise(topicId) {
        const response = await this.axios.get(`topics/${topicId}/exercises/compose`);

        return response.data;
    }

    async checkComposeExercise(topicId, exerciseId, words) {
        const body = { words }

        try {
            const response = await this.axios.post(`topics/${topicId}/exercises/compose/${exerciseId}/check`, body);

            return response.status == 202;
        } catch (error) {
            if (error.response && error.response.status === 409) {
                return error.response.data;
            }

            throw error;
        }
    }

    async checkChooseWordExercise(topicId, exerciseId, correctWordId) {
        const body = { correctWordId }
        try {
            const response = await this.axios.post(`topics/${topicId}/exercises/chose-correct-word/${exerciseId}`, body)

            return response.status == 204;
        }
        catch (error) {
            if (error.response && error.response.status === 422) {
                return false;
            }

            throw error;
        }
    }

    async getCorrect(topicId, exerciseId) {
        const response = await this.axios.get(`topics/${topicId}/exercises/chose-correct-word/${exerciseId}/correct`);

        return response.data;
    }
}

export default ExercisesApi;