import './wrong.credentials.css'
import React, { useEffect, useState, useRef } from 'react';

function WrongCredentials({ info }) {
    return (
        <div className='container2'>
            <div className='info'><span>{info}</span></div>
        </div>
    )
}

export default WrongCredentials;