import "./exercise.css";
import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import ProgressBar from "../../components/progressBar/progress.bar";
import NewWord from "../../components/newWords/new.word";
import ExerciseWordButton from "../../components/ExerciseWordButton/exercise.word.button";
import ExerciseFooter from "../../components/ExerciseFooter/exercise.footer";
import Loader from "../../components/loader/loader.component";
import { useLocation } from 'react-router-dom';
import ChooseWordExercise from "../../components/choose-word-exercise/choose.word.exercise";
import VoiceExercise from "../../components/voiceExercise/voice.exercise";
import ComposeExercise from "../../components/drag-and-drop-exercise/compose.exercise";
import factorySingleton from "../../services/factory";

const successAudio = new Audio('/sounds/success.mp3');
const failAudio = new Audio('/sounds/fail.mp3');

const Exercise = () => {
  const topicsApi = factorySingleton.topicsApi;

  const [progress, setProgress] = useState(0);

  const location = useLocation();
  const { topicId } = location.state || {};
  topicsApi.view(topicId)
  const [state, setState] = useState('Common')
  const [correctWord, setCorrectWord] = useState(null)

  const voiceExerciseRef = useRef();
  const exRef = useRef(null);
  const composeRef = useRef(null);

  const [currentExerciseType, setCurrentExerciseType] = useState(null);

  useEffect(() => {
    if (progress < 0) {
      setProgress(0)
    }
  }, [progress])

  useLayoutEffect(() => {
    setup();
  }, [])

  const defineExerciseType = () => {
    const number = Math.floor(Math.random() * 100);
    if (number <= 40) {
      return 'choose'
    }

    if (number > 40 && number <= 60) {
      return 'talk';
    }

    if (number > 60) {
      return "compose";
    }
  }

  const setup = async () => {
    setCorrectWord(null)
    await setCurrentExerciseType(null)
    const exType = defineExerciseType();
    setCurrentExerciseType(exType);
    setState('Common');
    // const exRef = getCurrentExerciseRef(exType);
    // await exRef.current?.setup();
  }

  const getCurrentExerciseRef = (type) => {
    if (type === 'choose') return exRef;
    if (type === 'talk') return voiceExerciseRef;
    if (type === 'compose') return composeRef;
  }

  const handleContinueClick = async (e) => {
    await setup();
  }

  const onResult = (result, correct) => {
    if (!result) {
      setProgress(progress - 5)
      setState('Fail')
      setCorrectWord(correct)
      failAudio.play();
    }
    else {
      setProgress(progress + 5)
      setState('Success')
      successAudio.play()
    }
  }

  const onFail = (correctAnswer) => {
    setProgress(progress - 5)
    setCorrectWord(correctAnswer)
    setState('Fail')
    failAudio.play();
  }

  const onSuccess = () => {
    setProgress(progress + 5)
    setState('Success')
    successAudio.play()
  }

  return (
    <div>
      <ProgressBar variable={progress} />

      {currentExerciseType === 'choose' && <ChooseWordExercise topicId={topicId} onSuccess={onSuccess} onFail={onFail} />}
      {currentExerciseType === 'talk' && <VoiceExercise ref={voiceExerciseRef} topicId={topicId} onResult={onResult} />}
      {currentExerciseType === "compose" && <ComposeExercise ref={composeRef} topicId={topicId} onSuccess={onSuccess} onFail={onFail} />}

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "12%",
        }}
      >
        <ExerciseFooter footer_state={state} onClick={(e) => handleContinueClick(e)} correctWord={correctWord} />
      </div>
    </div>
  );
};
export default Exercise;
