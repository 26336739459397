import "./continue.button.css";

const ContinueButton = ({ color, text, onClick }) => {
  return (
    <div>
      <button onClick={(e) => onClick ? onClick(e) : null}
        style={{ backgroundColor: `${color}` }}
        className="continue_button"
      >
        {text}
      </button>
    </div>
  );
};
export default ContinueButton;
