import "./login.css";
import CredentialsInput from "../../components/credentials-input/credentials.input";
import PaymentPageButton from "../../components/payment-page-button/payment.page.button";
import { useEffect, useState } from "react";
import factorySingleton from "../../services/factory";
import { useNavigate } from "react-router-dom";
import WrongCredentials from "../../components/wrong-credentials/wrong.credentials";
import Loader from "../../components/loader/loader.component";
import { useTranslation } from 'react-i18next';
const LoginScreen = () => {
  const { t, ready } = useTranslation();

  const navigate = useNavigate();
  const tokenService = factorySingleton.tokensService;
  const identityService = factorySingleton.identityApi;

  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);

  const [loginEnabled, setLoginEnabled] = useState(false);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  const passwordRegex = /^.{6,}$/

  const [loginFailed, setLoginFailed] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (tokenService.getTokens()) {
      navigate('/')
    }
  }, [])

  useEffect(() => {
    setLoginEnabled(emailRegex.test(email) && passwordRegex.test(password))
  }, [email, password])

  const signInClick = async () => {
    setLoading(true)
    const response = await identityService.login({ email, password });
    if (response.success) {
      tokenService.setToken(response.token);
      navigate("/");
    }
    else {
      setLoginFailed(true)
    }
    setLoading(false)
  };
  if (!ready){
    return (<Loader />)
  }

  return (
    <div className="login_wrapper">
      {loading && <Loader />}
      <div className="login_description">
        {t('signIn:title')}Í
      </div>
      {loginFailed && <WrongCredentials info={t('signId:wrongCredentials')} />}
      <div className="login_container">
        <div className="input_name">{t('signIn:inputs:email')}</div>
        <CredentialsInput
          form_type="email"
          form_placeholder="Enter your email"
          onChange={setEmail}
        />
        <div className="input_name">{t('signIn:inputs:password')}</div>
        <CredentialsInput
          form_type="password"
          form_placeholder="Enter your password"
          onChange={setPassword}
        />
        {/* <a href="#" class="password_recovery">
          Forgot password
        </a> */}
        <PaymentPageButton
          title="Sign in"
          buttonColor="#A180C8"
          titleColor="#fff"
          hoverColor="hoverColorViolet"
          onClick={signInClick}
          enabled={loginEnabled}
        />
        {/* <div className="google_button_container">
          <button className="google_button">
            <img src="images/google-icon.svg" alt="" className="google_icon" />
            Sign in with Google
          </button>
        </div> */}
        <p
          className="
registration_offer"
        >
          {t('signIn:signUpLinkSpan')}
          <a href="/sign-up" className="registration_offer_link">
            &nbsp;{t('signIn:signUpLink')}
          </a>
        </p>
      </div>
    </div>
  );
};
export default LoginScreen;
