import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import "./ScrollMenu.css";
import { useEffect } from "react";

function ScrollMenu({ data, onSlideChanged, onElClick, elRefs }) {
  const length = data.length;

  const settings = {
    infinite: length > 4,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: length > 4,
    swipe: length > 4,
    draggable: length > 4,
    swipeToSlide: length > 4,
    afterChange: (current) => {
      if (onSlideChanged) {
        onSlideChanged(current);
      }
    },

    responsive: [
      {
        breakpoint: 565, // ширина экрана в px
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: length >1,
          arrows: length > 1,
        }
      },
      
    ]
  };

  useEffect(() => {
   
  }, []);


  return (
    <div className="menu_wrapper">
      <div className="menu_container">
        <Slider {...settings}>
          {data.map((d, i) => (
            <div key={d.id} onClick={(e) => onElClick(e, d.id, d.img)} ref={elRefs.current[i]}>
              <div
                className="background_img"
                style={{
                  
                  height: "225px",
                  width: "90%", // ширина топика
                  borderRadius: 35,
                  
                }}
              >
                <div className="desc">
                  <p className="topic_name">{d.name}</p>
                  <p className="topic_text">{d.text}</p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default ScrollMenu;
