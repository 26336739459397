import React, { useEffect, useState } from 'react';
import './dots.loading.css';

const DotsLoading = ({ isLoading }) => {
    const [dots, setDots] = useState(1);

    useEffect(() => {
        const interval = setInterval(() => {
            if (!isLoading === false) {
                setDots(1);
                return
            }
            setDots((prev) => (prev === 3 ? 1 : prev + 1));
        }, 500);

        return () => clearInterval(interval);
    }, []);

    return (
        <>
            {isLoading && <div className="voice-recording">
                <div className="recording-indicator">
                    <div className="recording-circle"></div>
                    <span>Recording{'.'.repeat(dots)}</span>
                </div>
            </div>}
        </>
    );
};

export default DotsLoading;
