class IdentityApi {
    constructor(axios, languageProvider) {
        this.axios = axios;
        this.languageProvider = languageProvider;
    }

    async register({ name, email, password, recaptchaToken }) {
        try {
            const response = await this.axios.post("identity/api/identity",
                {
                    name,
                    email,
                    password
                },
                {
                    headers: {
                        'recaptcha': recaptchaToken
                    }
                }
            )

            return { success: true, token: response.data.token }
        }
        catch (error) {
            if (error.response && error.response.status === 409) {
                return { success: false }
            }
            else if (error.response && error.response.status === 418) {
                return { success: false, recaptcha: true }
            }
            else {
                throw error;
            }
        }
    }

    async login({ email, password }) {
        try {
            const response = await this.axios.post("identity/api/login", {
                email,
                password
            })
            return { success: true, token: response.data.token }
        }
        catch (error) {
            if (error.response && error.response.status === 401) {
                return { success: false }
            } else {
                throw error;
            }
        }
    }

    async getTrialAllonece() {
        const response = await this.axios.get('identity/api/actions');
        return response.data;
    }
}

export default IdentityApi;