import React, { useState, useRef } from 'react';
import "./talk.button.css";
import DotsLoading from "../dots-loading/dots.loading";

const encodeWAV = (audioBuffer) => {
  const numOfChan = audioBuffer.numberOfChannels,
    length = audioBuffer.length * numOfChan * 2 + 44,
    buffer = new ArrayBuffer(length),
    view = new DataView(buffer),
    channels = [],
    sampleRate = audioBuffer.sampleRate,
    bitsPerSample = 16;
  let offset = 0,
    pos = 0;

  // Write WAV container header
  const writeString = (s) => {
    for (let i = 0; i < s.length; i++) {
      view.setUint8(pos++, s.charCodeAt(i));
    }
  };

  // Write little-endian 32-bit integer
  const writeUint32 = (data) => {
    view.setUint32(pos, data, true);
    pos += 4;
  };

  // Write little-endian 16-bit integer
  const writeUint16 = (data) => {
    view.setUint16(pos, data, true);
    pos += 2;
  };

  writeString('RIFF');
  writeUint32(length - 8); // file length - 8
  writeString('WAVE');

  writeString('fmt ');
  writeUint32(16);
  writeUint16(1); // PCM format
  writeUint16(numOfChan);
  writeUint32(sampleRate);
  writeUint32(sampleRate * numOfChan * bitsPerSample / 8);
  writeUint16(numOfChan * bitsPerSample / 8);
  writeUint16(bitsPerSample);

  writeString('data');
  writeUint32(length - pos - 4);

  // Write interleaved PCM samples
  for (let i = 0; i < numOfChan; i++) {
    channels.push(audioBuffer.getChannelData(i));
  }

  while (offset < audioBuffer.length) {
    for (let i = 0; i < numOfChan; i++) {
      let sample = Math.max(-1, Math.min(1, channels[i][offset]));
      sample = (sample * 32767) | 0;
      view.setInt16(pos, sample, true);
      pos += 2;
    }
    offset++;
  }

  return new Blob([buffer], { type: 'audio/wav' });
};

const TalkButton = ({ onRecorded }) => {
  const [isRecording, setIsRecording] = useState(false);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const streamRef = useRef(null);

  const handleStartRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      streamRef.current = stream;
      mediaRecorderRef.current = new MediaRecorder(stream, { mimeType: 'audio/mp4' });
      audioChunksRef.current = [];

      mediaRecorderRef.current.ondataavailable = event => {
        audioChunksRef.current.push(event.data);
      };

      mediaRecorderRef.current.onstop = () => {
        (async () => {
          const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/mp4' });
          const audioUrl = URL.createObjectURL(audioBlob);

          // Convert audioBlob from mp4 to wav
          try {
            const arrayBuffer = await audioBlob.arrayBuffer();
            const audioContext = new (window.AudioContext || window.webkitAudioContext)();
            const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
            const wavBlob = encodeWAV(audioBuffer);
            const wavUrl = URL.createObjectURL(wavBlob);
            if (onRecorded) {
              onRecorded(wavUrl, wavBlob);
            }
            audioContext.close();
          } catch (error) {
            console.error('Error converting audio to WAV:', error);
          }

          // Stop all tracks to free resources
          streamRef.current.getTracks().forEach(track => track.stop());
          streamRef.current = null;
        })();
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
    } catch (err) {
      alert('Please allow microphone access');
      console.log('access error: ' + err);
    }
  };

  const handleStopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  const onClick = () => {
    if (isRecording) {
      handleStopRecording();
    }
    else {
      handleStartRecording();
    }
  }

  return (
    <div className="voice_exercise_button_container">
      <button
        className="voice_exercise_button"
        onClick={onClick}
      >
        {!isRecording && <img
          src="./images/microphone-icon.svg"
          alt=""
          style={{ paddingRight: "18px" }}
        />}
        {isRecording ? '' : 'Click and talk'}
        <div>
          <DotsLoading isLoading={isRecording} />
        </div>
      </button>
    </div>
  );
};

export default TalkButton;
