import "./topics.screen.css";
import ScrollMenu from "../../components/scrollMenu/ScrollMenu";
import Search from "../../components/search/Search";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import factorySingleton from "../../services/factory";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import TopicMenu from "../../components/topic-menu/topic.menu";
import useOutsideClick from "../../helpers/click.outside";
import { useTranslation } from 'react-i18next';

const Topics = () => {
  const { t, i18n } = useTranslation();
  const topicsApi = factorySingleton.topicsApi;

  const [isViewedSearching, setIsViewedSearching] = useState(false);
  const [isPopularSearching, setIsPopularSearching] = useState(false);
  const [viewedTopics, setViewedTopics] = useState([]);
  const [popularTopics, setPopularTopics] = useState([]);

  const [searchSample, setSearchSample] = useState("");
  const [menuPosition, setMenuPosition] = useState(null);

  const viewedRefs = useRef([]);
  const popularRefs = useRef([]);

  const [focusedTopic, setFocusedTopic] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      setIsPopularSearching(true);
      setIsViewedSearching(true);

      const viewedPromise = topicsApi.getViewed(searchSample, 0, 1000);
      const popularPromise = topicsApi.getPopular(searchSample, 0, 1000);

      const [viewed, popular] = await Promise.all([
        viewedPromise,
        popularPromise,
      ]);

      if (Array.isArray(viewed?.topics)) {
        setViewedTopics(viewed.topics.map((x) => toModel(x)));
      }

      if (Array.isArray(popular?.topics)) {
        setPopularTopics(popular.topics.map((x) => toModel(x)));
      }

      setIsPopularSearching(false);
      setIsViewedSearching(false);
    };

    fetchData();
  }, [searchSample]);

  const combinedRefs = useRef([]);
  useEffect(() => {
    viewedRefs.current = viewedTopics.map((_, i) => viewedRefs.current[i] || React.createRef());
    popularRefs.current = popularTopics.map((_, i) => popularRefs.current[i] || React.createRef());
    combinedRefs.current = [...viewedRefs.current, ...popularRefs.current];

    console.log(viewedRefs);
    console.log('combinated')
    console.log(combinedRefs)
  }, [viewedTopics, popularTopics]);

  const toModel = (topic) => {
    return {
      id: topic.id,
      name: topic.name,
      img: factorySingleton.apiBaseUrl + `/topics/${topic.id}/image`,
    };
  };

  const handleSlideChanged = (current) => {
    console.log("Slide changed to:", current);
  };


  const changePosition = (e, id) => {
    setMenuPosition(null);
    const scrollTop = window.scrollY;
    const scrollLeft = window.scrollX;

    setMenuPosition({ top: e.clientY + scrollTop, left: e.clientX + scrollLeft });
    setFocusedTopic(id);
  }
  const handleElementClick = (event, id, img) => {
    console.log("Element clicked:", id, img);

    changePosition(event, id);
  };

  const handlePopularClick = (event, id) => {
    console.log('popular clicl')
    changePosition(event, id);
  }

  // useOutsideClick(combinedRefs, () => {
  //   console.log("Clicked outside of viewed topics");
  //   setMenuPosition(null);
  // });

  // useOutsideClick(popularRefs, () => {
  //   console.log("Clicked outside of popular topics");
  //   setMenuPosition(null);
  // });

  return (
    <div>
      {menuPosition != null && <TopicMenu position={menuPosition} topicId={focusedTopic} />}
      <div className="topics-container">
        <div className="search-bar">
          <Search onSearch={(value) => setSearchSample(value)} />
        </div>
        {viewedTopics.length > 0 && (
          <>
            <p
              style={{
                fontFamily: "Montserrat",
                fontSize: "35px",
                fontWeight: 600,
                lineHeight: "49px",
                textAlign: "left",
                marginBottom: "24px",
              }}
            >
              {t('topics:inProgress')}
            </p>
            <div
              style={{
                width: "68%",
              }}
            >
              <ScrollMenu
                data={viewedTopics}
                onSlideChanged={handleSlideChanged}
                onElClick={handleElementClick}
                elRefs={viewedRefs}
              />
            </div>
          </>
        )}
        <p
          style={{
            marginTop: "62px",
            fontFamily: "Montserrat",
            fontSize: "35px",
            fontWeight: 600,
            lineHeight: "49px",
            textAlign: "left",
          }}
        >
          {t('topics:popularTopics')}
        </p>
        <div
          style={{
            width: "68%",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {popularTopics.map((popular_topic, index) => (
            <div
              className="popularTopicElement"
              key={popular_topic.id}
              ref={popularRefs.current[index]}
              onClick={(e) => handlePopularClick(e, popular_topic.id)}
              style={{
                
                height: 225,
                width: "22.5%",
                borderRadius: 35,
                marginLeft: "25px",
                marginBottom: "30px",
              }}
            >
              <div className="desc">
                <p className="topic_name">{popular_topic.name}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Topics;
