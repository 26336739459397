import "./trial.component.css";
import { useTranslation } from 'react-i18next';
function TrialInfo({ exercises, maxExercises, generations, maxGenerations }) {
  const { t, i18n } = useTranslation();
  return (
    <div className="trial_wrapper">
      <div className="subscription_spoiler_container">
        <div className="subscription_spoiler_item">
          <div className="subscription_spoiler_text">
            <span className="subscription_spoiler_span">{exercises}</span>{" "}
            {t('subscription:base:trial:freeExercises')}{" "}
            <span className="subscription_spoiler_span">{maxExercises}</span>
          </div>
          <span className="not_active_subscription_free">Free</span>
        </div>
        <div className="subscription_spoiler_item">
          <div className="subscription_spoiler_text">
            <span className="subscription_spoiler_span">{generations}</span>{" "}
            {t('subscription:base:trial:freeGenerations')}{" "}
            <span className="subscription_spoiler_span">{maxGenerations}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrialInfo;
