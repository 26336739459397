import "./active.subscription.css";
import PaymentPageButton from "../payment-page-button/payment.page.button";
import SubscriptionStatus from "../subscription-status-flag/subscription.status.flag";
import { useCallback } from "react";
import factorySingleton from "../../services/factory";
import { useTranslation } from 'react-i18next';

const ActiveSubscription = ({ status, date, price, cancelClick, cancelled }) => {
  const { t, i18n } = useTranslation();
  const examples = t('subscription:examples', { returnObjects: true });
  return (
    <div className="subscription_button_wrapper">
      <div className="subscription_button_container">
        <div className="subscription_status">
          <span className="subscription_plan">{status}</span>
          <SubscriptionStatus status={cancelled ? 'Cancelled' : 'Active'} />
        </div>
        <span className="subscription_date">{date}</span>
        <span className="subscription_price">${price}{t('subscription:subscriptionPrice')}</span>
        {!cancelled && <div className="subscriptions_payment_button">
          <PaymentPageButton
            title={t('subscription:pro:cancelSubscriptionButton')}
            buttonColor="#FFFFFF"
            titleColor="#A180C8"
            hoverColor="hoverColorWhite"
            enabled="disabled"
            onClick={cancelClick}
          />
        </div>}
        {/* {cancelled &&
          <span>
            Your subscription has been cancelled. We won't charge you anymore.
          </span>} */}
        <ul className="subscription_info">
        {examples.map((x, i) =>
            <li key={i}>{x}</li>
          )
          }
        </ul>
      </div>
    </div>
  );
};

export default ActiveSubscription;
