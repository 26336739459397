import axios from 'axios'
import TokensService from './tokens.service'
import TokensApi from './api/tokens.api';
import TokenProvider from './token.provider';
import LanguageProvider from './language.provider';
import TopicsApi from './api/topics.api';
import WordsApi from './api/words.api';
import qs from 'qs';
import ExplanationsApi from './api/explanations.api';
import ExercisesApi from './api/exercises.api';
import IdentityApi from './api/identity.api'
import SubscriptionsApi from './api/subscriptions.api';
import LanguagesApi from './api/languages.api';

class Factory {
    constructor() {
        this.apiBaseUrl = null;
        this.apiBaseUrlV2 = null;
        this.axiosInstance = null;
        this.axiosGatewayInstance = null;
        this.tokensApi = null;
        this.tokensProvider = null;
        this.tokensService = null;
        this.languageProvider = null;
        this.topicsApi = null;
        this.wordsApi = null;
        this.explanationsApi = null;
        this.exercisesApi = null;
        this.identityApi = null;
        this.subscriptionsApi = null;
        this.languagesApi = null;
    }

    setup() {
        // axios
        this.axiosInstance = axios.create({
            baseURL: process.env.REACT_APP_ApiBaseUrl,
            headers: {
                'Content-Type': 'application/json',
            },
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
            withCredentials: false
        });

        this.axiosGatewayInstance = axios.create({
            baseURL: process.env.REACT_APP_ApiBaseUrl_V2,
            headers: {
                'Content-Type': 'application/json',
            },
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
            withCredentials: false
        })

        // tokens
        this.tokensProvider = new TokenProvider();

        this.tokensApi = new TokensApi(axios.create({
            baseURL: process.env.REACT_APP_ApiBaseUrl,
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: false
        })); // тут используем отдельный instance чтобы не зациклиться

        this.tokensService = new TokensService(this.tokensApi, this.tokensProvider, this.axiosGatewayInstance)
        this.apiBaseUrl = process.env.REACT_APP_ApiBaseUrl
        this.apiBaseUrlV2 = process.env.REACT_APP_ApiBaseUrl_V2
        // authorization header 
        this.axiosInstance.interceptors.request.use(
            async config => {
                const token = this.tokensService.getTokens(); // Получаем токен асинхронно
                if (token) {
                    config.headers.Authorization = `${token}`;
                }
                return config;
            }
        );

        this.axiosGatewayInstance.interceptors.request.use(
            async config => {
                const token = this.tokensService.getTokens(); // Получаем токен асинхронно
                if (token) {
                    config.headers.Authorization = `${token}`;
                }
                return config;
            }
        );

        this.languagesApi = new LanguagesApi(this.axiosGatewayInstance);
        this.languageProvider = new LanguageProvider(this.languagesApi);
        this.topicsApi = new TopicsApi(this.axiosInstance, this.languageProvider)
        this.wordsApi = new WordsApi(this.axiosInstance, this.languageProvider)
        this.explanationsApi = new ExplanationsApi(this.axiosInstance);
        this.exercisesApi = new ExercisesApi(this.axiosInstance);
        this.identityApi = new IdentityApi(this.axiosGatewayInstance)
        this.subscriptionsApi = new SubscriptionsApi(this.axiosGatewayInstance)
    }
}


const factorySingleton = new Factory();
export default factorySingleton;