import { Axios } from 'axios';

class LanguagesApi {
    constructor(axios: Axios) {
        this.axios = axios;
    }

    async getAll(){
        const response = await this.axios.get('foreign/api/languages');
        if(response.status !== 200) throw new Error('Cannot get languages');

        return response.data;
    }
}

export default LanguagesApi;