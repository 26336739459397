import "./exercise.footer.css";
import ContinueButton from "../ContinueButton/continue.button";
const ExerciseFooter = ({ footer_state, onClick, correctWord }) => {
  const determineFooterState = () => {
    let footerState = "";
    let footerIcon = "";
    let footerStatusText = "";

    switch (footer_state) {
      case "Common":
        break;
      case "Success":
        footerState = "footer_success";
        footerIcon = "footer_icon_success";
        footerStatusText = "footer_status_text_success";
        break;
      case "Fail":
        footerState = "footer_fail";
        footerIcon = "footer_icon_fail";
        footerStatusText = "footer_status_text_fail";
        break;
      default:
        break;
    }

    return { footerState, footerIcon, footerStatusText };
  };

  const { footerState, footerIcon, footerStatusText } = determineFooterState();

  let footerButtonColor = "";
  let footerButtonText = "";
  let footerText = "";

  if (footer_state === "Success") {
    footerButtonColor = "#9FC66C";
    footerButtonText = "Continue";
    footerText = "Perfect!";
  } else if (footer_state === "Fail") {
    footerButtonColor = "#FF0000";
    footerButtonText = "Continue";
    footerText = `Correct answer: ${correctWord}`;
  }

  return (
    <footer class={`footer ${footerState}`}>
      <div class="footer_content">
        <div class="status_icon_container">
          <div class={`footer_icon ${footerIcon}`}></div>
          {footer_state !== "Common" && (
            <div class={`footer_status_text ${footerStatusText}`}>
              {footerText}
            </div>
          )}
        </div>
        {footer_state !== "Common" && (
          <div class="button_container">
            <ContinueButton color={footerButtonColor} text={footerButtonText} onClick={onClick} />
          </div>
        )}
      </div>
    </footer>
  );
};
export default ExerciseFooter;
