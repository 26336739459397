import "./generate.topic.box.css";

const GenerateTopicBox = ({ box_title, onClick }) => {
  return (
    <div className="generate_topic_box_wrapper" onClick={onClick}
      style={{
        marginTop: "46px",
        width: "26.5vw",
        minWidth:"225px",
        maxWidth:"330px",
        height: "40vh",
        minHeight:"168px",
        maxHeight:"295px",
        textAlign: "center",
        display: "flex",

        
        borderRadius: 35,
        
      }}
    >
      <div className="generate_topic_box_container"
        style={{
          display: "flex",
          color: "white",
          margin: "42px 0px 0px 24px",
          fontFamily: " Montserrat",
          fontWeight: 500,
        }}
      >
        {box_title}
      </div>
    </div>
  );
};

export default GenerateTopicBox;
