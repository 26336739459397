import { useEffect } from "react";

const UmamiTracker = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = process.env.REACT_APP_UMAMI_URL + '/script.js';
        script.async = true;
        script.defer = true;
        script.setAttribute('data-website-id', process.env.REACT_APP_UMAMI_SITE_ID);
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return null;
};

const trackEvent = async (event_type) => {
    window.umami.track(event_type, '');
}

export { UmamiTracker, trackEvent };