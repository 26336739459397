
import PaymentPageButton from "../payment-page-button/payment.page.button";
import CloseButton from "../closeButton/close.button";
const FailedPaymentPopup = ({onClick, onCrossClick,}) => {
  return (
    <div className="PaymentPagePopUp_wrapper">
      <div className="PaymentPagePopUp_container">
        <div className="popUp_close_button_wrapper">
          <CloseButton customClose={onCrossClick} />
        </div>
        <div className="subscribe_popUp_title">Unfortunately, the payment didn't go through.</div>
        <img
          src="./images/wrong_payment.png" // ./images/popUp-video-tuturial.svg
          className="subscribe_popUp_icon"
          onClick={onCrossClick}
        />
        <PaymentPageButton
          title="Continue"
          buttonColor="#A180C8"
          titleColor="#fff"
          hoverColor="hoverColorViolet"
          enabled={true}
          onClick={onClick}
        />
        <span className="support">Contact support: support@polyglotio.com</span>
      </div>
    </div>
  );
};

export default FailedPaymentPopup;
