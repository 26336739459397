// @flow
import axios, { Axios } from 'axios'

class SubscriptionsApi {
    constructor(axios: Axios) {
        this.axios = axios
    }

    async getSubscription(userId) {
        try {
            const response = await this.axios.get(`subscriptions/api/customers/${userId}/subscriptions`)
            return response.data
        } catch (error) {
            if (error.response && error.response.status === 404) {
                return null;
            }

            throw error;
        }
    }

    async getStripeLink(userId) {
        const response = await this.axios.get(`stripe/api/stripe/customers/${userId}/payments/link`)
        return response.data.url
    }

    async getPaymentSession(sessionId) {
        try {
            const response = await this.axios.head(`stripe/api/stripe/customers/123/payments/link/${sessionId}/paid`)
            return response.status === 200
        } catch (error) {
            if (error.response && error.response.status === 409) {
                return false;
            }

            throw error;
        }

    }

    async cancellSubscription() {
        const data = await this.axios.delete(`stripe/api/stripe/subscription`)
    }
}

export default SubscriptionsApi;