import React, { useState } from "react";
import "./exercise.word.button.css";

const ExerciseWordButton = ({ onClick, id, word, state }) => {
  const determineButtonState = () => {
    switch (state) {
      case "Common":
        return "exercise_button_common_color";
      case "Success":
        return "exercise_button_success_color";
      case "Fail":
        return "exercise_button_fail_color";
      default:
        return "";
    }
  };

  return (
    <div style={{ paddingLeft: "24px", marginTop: "3%" }} onClick={(e) => onClick(e, id)}>
      <button className={`word_button ${determineButtonState()}`}>
        {word}
      </button>
    </div>
  );
};

export default ExerciseWordButton;
