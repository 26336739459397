import ExerciseWordFlexible from "../exercise-word-flexible/exercise.word.flexible";
import "./drag.and.drop.area.css";

const DragAndDropArea = ({ words, onWordClick, onDrop, onElDragStart }) => {
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  return (
    <div className="drag_and_drop_exercise_words_area_wrapper">
      <div className="drag_and_drop_exercise_words_area"
        onDrop={onDrop}
        onDragOver={handleDragOver}
        draggable>
        {words?.map((v, i) => <ExerciseWordFlexible word={v} onClick={() => onWordClick(v, i)} key={i} onDragStart={() => onElDragStart(i)} />)}
      </div>
    </div>
  );
};

export default DragAndDropArea;
